<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="row justify-between">
        <q-select
          bottom-slots
          :options="$LIST_JENJANG"
          v-model="selJenjang"
          @input="refreshKiri"
          outlined
          label="Jenjang"
          style="width: 310px;"
          ><template v-slot:hint>
            <span class="text-negative"
              >*Tingkat '0' menandakan siswa yang belum memiliki tingkat</span
            >
          </template></q-select
        >
        <q-select
          bottom-slots
          :options="$LIST_PENGASUHAN"
          v-model="selPengasuhan"
          @input="refreshKanan"
          outlined
          label="Jenjang"
          style="width: 310px;"
          ><template v-slot:hint>
            <span class="text-negative"
              >*hanya kamar yang sudah memiliki musyrif/ah yang dapat di
              isi</span
            >
          </template></q-select
        >
      </q-card-section>
      <q-card-section class="row justify-between q-pt-none">
        <div class="row q-gutter-sm">
          <q-select
            :options="listTingkat"
            v-model="selTingkat"
            @input="getKamarList(), getSiswaList()"
            outlined
            label="Tingkat"
            style="width: 150px;"
          ></q-select>
        </div>
        <div class="row q-gutter-sm">
          <q-select
            class="self-end"
            use-input
            @filter="filterFn"
            :options="filteredListKamar"
            v-model="selKamar"
            :disable="selAsrama == ''"
            outlined
            @input="getSiswaKamarList(), getKetuaList()"
            label="Kamar"
            style="width: 150px;"
          ></q-select>
          <q-select
            class="self-end"
            :options="asrama"
            v-model="selAsrama"
            outlined
            @input="getKamarList"
            label="Asrama"
            style="width: 150px;"
          ></q-select>
        </div>
      </q-card-section>
      <q-card-section class="row justify-between q-pt-none">
        <q-input outlined label="Cari Siswa/NISN" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <q-select
          class="self-end"
          use-input
          @filter="filterFnKetua"
          :options="filteredListKetua"
          v-model="selKetua"
          :disable="selKamar == ''"
          @input="(isChanged = true), simpan()"
          outlined
          label="Ketua"
          style="width: 150px;"
        ></q-select>
      </q-card-section>
      <q-card-section class="row justify-between items-center">
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          style="width:45%; height: calc(100vh - 250px)"
          class="stickyTable bg-grey-4"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th style="width:20px;">
                <q-checkbox
                  @input="checkingAll('kiri')"
                  v-model="checkAllKiri"
                ></q-checkbox>
              </th>
              <th>no</th>
              <th>nisn</th>
              <th>nama</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="(val, i) in filSiswa" :key="i">
              <td>
                <q-checkbox v-model="val.check"></q-checkbox>
              </td>
              <td>{{ i + 1 }}</td>
              <td>{{ val.nisn }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
        <div class="column q-gutter-sm">
          <q-btn
            outline
            color="black"
            :disable="selKamar == ''"
            @click="(isChanged = true), toGlobal()"
            icon="keyboard_arrow_left"
          ></q-btn>
          <q-btn
            outline
            color="black"
            :disable="selKamar == ''"
            @click="(isChanged = true), toKamar()"
            icon="keyboard_arrow_right"
          ></q-btn>
        </div>
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          style="width:45%; height: calc(100vh - 250px)"
          class="stickyTable bg-grey-4"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th style="width:20px;">
                <q-checkbox
                  :disable="selKamar == ''"
                  @input="checkingAll('kanan')"
                  v-model="checkAllKanan"
                ></q-checkbox>
              </th>
              <th>no</th>
              <th>nisn</th>
              <th>nama</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-show="!selKamar">
              <td colspan="4" class="text-caption text-grey-7 text-center">
                Silahkan pilih asrama dan kamar terlebih dahulu
              </td>
            </tr>
            <tr v-show="listKanan.length == 0 && selKamar">
              <td colspan="4" class="text-caption text-grey-7 text-center">
                Tidak Ada Siswa Dalam kamar Ini
              </td>
            </tr>
            <tr v-for="(val, i) in listKanan" :key="i">
              <td>
                <q-checkbox v-model="val.check"></q-checkbox>
              </td>
              <td>{{ i + 1 }}</td>
              <td>{{ val.nisn }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
      <q-card-actions align="left">
        <q-btn
          outline
          color="primary"
          label="Import Excel"
          @click="showDialogImport"
        ></q-btn>
      </q-card-actions>
    </q-card>
    <q-dialog v-model="show" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <p>Apa anda yakin ingin menyimpan perubahan?</p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            label="Ya"
            color="primary"
            @click="simpan"
            v-close-popup
          />
          <q-btn flat label="Tidak" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogImportKelasKamar from "@/components/DialogImportKelasKamar";
import General from "@/mixins/General";
export default {
  mixins: [General],
  data() {
    return {
      file: null,
      insert: true,
      kamar_all: [],

      show: false,
      isChanged: false,
      checkAllKanan: false,
      checkAllKiri: false,
      selJenjang: this.$LIST_JENJANG[0],
      selPengasuhan: this.$LIST_PENGASUHAN[0],
      listTingkat: [],
      selTingkat: "",
      listKamar: [],
      filteredListKamar: [],
      filteredListKetua: [],
      listKiri: [],
      listKanan: [],
      listKetua: [],
      asrama: [],
      selAsrama: "",
      selKamar: "",
      selKetua: null,
      searchTerm: "",
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.listKiri;
      else {
        return this.listKiri.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  },
  async mounted() {
    await this.refreshKiri();
    await this.refreshKanan();
  },
  methods: {
    showDialogImport() {
      this.$q
        .dialog({
          component: DialogImportKelasKamar,
          parent: this,
          asal: "kamar",
        })
        .onOk(async (resp) => {
          let file = await this.$hlp.ReadXLSX(resp.file);
          this.fileReady(file);
        });
    },

    async fileReady(file) {
      this.$q.loading.show({ message: "please wait" });
      let listKamar = [];
      let rows = file.split("\n");
      for (let item of rows) {
        let temp = item.split(";");
        if (temp[0] != "") {
          if (parseInt(temp[0]) > 0) {
            let carier = {};
            carier.id = temp[0];
            carier.gender = temp[3];
            carier.kamar = temp[6];
            carier.id_kamar = -1;
            listKamar.push(carier);
          }
        }
      }

      let resp = null;

      resp = await this.$http.get(
        `/pengasuhan/isi_kamar/getkamar/PENGASUHAN PUTRA`
      );
      let kamar_putra = resp.data;

      resp = await this.$http.get(
        `/pengasuhan/isi_kamar/getkamar/PENGASUHAN PUTRI`
      );
      let kamar_putri = resp.data;

      let siswa_putra = listKamar.filter((val) => {
        return val.gender == "L";
      });

      let siswa_putri = listKamar.filter((val) => {
        return val.gender == "P";
      });

      for (let kamar of kamar_putra) {
        for (let siswa of siswa_putra) {
          if (siswa.kamar == kamar.label) {
            siswa.id_kamar = kamar.value;
          }
        }
      }

      for (let kamar of kamar_putri) {
        for (let siswa of siswa_putri) {
          if (siswa.kamar == kamar.label) {
            siswa.id_kamar = kamar.value;
          }
        }
      }

      await this.$http.put(`/pengasuhan/kamar/inputexcel`, siswa_putra);
      await this.$http.put(`/pengasuhan/kamar/inputexcel`, siswa_putri);
      this.$q.loading.hide();
      this.MNotifyPositive("Berhasil import data excel");
      this.refreshKiri();
      this.refreshKanan();
      this.selKamar = null;
      this.listKanan = [];
      this.selKetua = null;
    },
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.filteredListKamar = this.listKamar;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredListKamar = this.listKamar.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    filterFnKetua(val, update) {
      if (val === "") {
        update(() => {
          this.filteredListKetua = this.listKetua;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredListKetua = this.listKetua.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    async refreshKanan() {
      this.selAsrama = "";
      this.selKamar = "";
      this.selKetua = "";
      this.listKamar = [];
      await this.getAsramaList();
    },
    async refreshKiri() {
      await this.getTingkatList();
      await this.getSiswaList();
    },
    async getAsramaList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/pengasuhan/asrama/getlistasrama/${this.selPengasuhan}`,
        this.$hlp.getHeader()
      );
      this.asrama = resp.data;
      this.$q.loading.hide();
    },
    async getKetuaList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          "/pengasuhan/kamar/getketua/" + this.selKamar.value,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listKetua = resp.data;
        });
      await this.$http
        .get(
          "/pengasuhan/kamar/cekketua/" + this.selKamar.value,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.selKetua = resp.data.id_ketua_kamar;
        });
      if (this.selKetua) {
        await this.$http
          .get(
            "/pengasuhan/kamar/curketua/" + this.selKetua,
            this.$hlp.getHeader()
          )
          .then((respon) => {
            this.selKetua = respon.data;
          });
      }
      this.$q.loading.hide();
    },
    async getTingkatList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          "/pengasuhan/kamar/gettingkat/" + this.selJenjang,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listTingkat = resp.data;
          this.selTingkat = resp.data[this.listTingkat.length - 1];
        });
      this.$q.loading.hide();
    },
    async simpan() {
      this.$q.loading.show({ message: "Menyimpan Data" });
      console.log("saving");
      let idIn = [];
      let idOut = [];
      this.listKanan.forEach((number, index) => {
        idIn.push(number.id);
      });

      this.listKiri.forEach((number, index) => {
        idOut.push(number.id);
      });
      await this.$http
        .put(
          "/pengasuhan/kamar/isi/" + this.selKamar.value,
          idIn,
          this.$hlp.getHeader()
        )
        .then((resp) => {});
      await this.$http
        .put("/pengasuhan/kamar/isi", idOut, this.$hlp.getHeader())
        .then((resp) => {});
      if (this.selKetua) {
        await this.$http
          .put(
            "/pengasuhan/kamar/changeketua/" +
              this.selKamar.value +
              "/" +
              this.selKetua.value,
            this.$hlp.getHeader()
          )
          .then((resp) => {});
      } else {
        await this.$http
          .put(
            "/pengasuhan/kamar/changeketua/" + this.selKamar.value,
            this.$hlp.getHeader()
          )
          .then((resp) => {});
      }
      await this.getSiswaKamarList();
      await this.getKetuaList();
      await this.getSiswaList();
      this.$q.loading.hide();
    },
    async toKamar() {
      let temp = [];
      for (let item in this.listKiri) {
        if (this.listKiri[item].check) {
          temp.push(this.listKiri[item].id);
          this.listKanan.push(this.listKiri[item]);
          let tempketua = {
            label: "",
            value: "",
          };
          tempketua.label = this.listKiri[item].nama;
          tempketua.value = this.listKiri[item].value;
          this.listKetua.push(tempketua);
        }
      }
      for (let item in temp) {
        this.listKiri = this.listKiri.filter(function(x) {
          return x.id != temp[item];
        });
      }
      await this.simpan();
      this.$q.notify({
        message: "proses pemindahan siswa ke kamar berhasil",
        color: "positive",
      });
    },
    async toGlobal() {
      let temp = [];
      for (let item in this.listKanan) {
        if (this.listKanan[item].check) {
          temp.push(this.listKanan[item].id);
          this.listKiri.push(this.listKanan[item]);
        }
      }
      for (let item in temp) {
        this.listKanan = this.listKanan.filter(function(x) {
          return x.id != temp[item];
        });
        this.listKetua = this.listKetua.filter(function(x) {
          return x.value != temp[item];
        });
      }
      let tempKetua = false;
      if (this.selKetua != null) {
        for (let item in temp) {
          if (temp[item] == this.selKetua.value) {
            tempKetua = true;
            this.$q.notify({
              message: "Ketua kamar dikeluarkan!",
              color: "negative",
            });
          }
        }
      }
      if (tempKetua) {
        this.selKetua = null;
      }
      await this.simpan();
      this.$q.notify({
        message: "proses pengeluaran siswa dari kamar berhasil",
        color: "positive",
      });
    },
    checkingAll(pos) {
      if (pos == "kanan") {
        if (this.checkAllKanan) {
          for (let item in this.listKanan) {
            this.listKanan[item].check = true;
          }
        } else {
          for (let item in this.listKanan) {
            this.listKanan[item].check = false;
          }
        }
      } else if (pos == "kiri") {
        this.$q.loading.show({ message: "please wait" });
        if (this.checkAllKiri) {
          for (let item in this.listKiri) {
            this.listKiri[item].check = true;
          }
        } else {
          for (let item in this.listKiri) {
            this.listKiri[item].check = false;
          }
        }
        this.$q.loading.hide();
      }
    },
    async getKamarList() {
      this.$q.loading.show({ message: "Memuat Data Kamar..." });
      await this.$http
        .get(
          "/pengasuhan/kamar/getkamar/" + this.selAsrama.value,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listKamar = resp.data;
        });
      this.$q.loading.hide();
    },
    async getSiswaList() {
      this.$q.loading.show({ message: "Memuat Data siswa..." });
      await this.$http
        .get(
          "/pengasuhan/kamar/getsiswa/" +
            this.selJenjang +
            "/" +
            this.selTingkat.value,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          resp.data.forEach((el) => {
            el.check = false;
          });
          this.listKiri = resp.data;
        });
      this.$q.loading.hide();
    },
    async getSiswaKamarList() {
      await this.$http
        .get(
          "/pengasuhan/kamar/getsiswa/" +
            this.selJenjang +
            "/" +
            this.selTingkat.value +
            "/" +
            this.selKamar.value,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          resp.data.forEach((el) => {
            el.check = false;
          });
          this.listKanan = resp.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
